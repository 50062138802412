import api from './../../service/api';

// Action Types
export const ADD_TO_CART = 'ADD_TO_CART';
export const VIEW_CART = 'VIEW_CART';

// Add product to the cart
export const addToCart = (productId, quantity) => async (dispatch) => {
  try {

    // check token 
    const token = localStorage.getItem('token');
    
    const userId = token ? JSON.parse(atob(token.split('.')[1])).userId : null;

    // Set Authorization header if token exists
    const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};

    // Send the product ID, quantity, and userId to the backend
    const response = await api.post('/cart/add', { productId, quantity, user: userId }, config);
    
    // Dispatch the action with the response data
    dispatch({ type: ADD_TO_CART, payload: response.data });
  } catch (error) {
    console.error('Failed to add to cart', error);
  }
};

// View the cart
export const viewCart = () => async (dispatch) => {
  try {
    // Get token from localStorage
    const token = localStorage.getItem('token');

    // Set Authorization header if token exists
    const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};

    const { data } = await api.get('/cart/viewcart', config); // Modify this endpoint as needed
    console.log(data)
    // Dispatch the action with the cart data
    dispatch({
      type: VIEW_CART,
      payload: data,
    });
  } catch (error) {
    console.error("Error fetching cart data:", error);
    throw error; // or dispatch an error action if you want to handle this globally
  }
};
