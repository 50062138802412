import React, { useEffect, useState } from "react";
import { fetchProducts } from './../../redux/actions/productActions';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const ProductFeatures = () => {
  const dispatch = useDispatch()
  const products = useSelector((state) => state.products.products);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  return (
    <div className="bg-white py-16 px-8">
      {/* Header Section */}
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold text-gray-800">Product Features</h2>
        <p className="text-gray-600 mt-2">
          Don't Miss Today's Featured Deals
        </p>
      </div>

      {/* Product Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 max-w-7xl mx-auto">
        {products.map((product) => (
          <Link to={`/products/viewproduct/${product?._id}`}>
            <div className="max-w-xs bg-white border border-gray-200 rounded-lg shadow-md">
              <div className="relative">
                <img className="w-full rounded-t-lg" src={product.images[0]} alt={product.title} />
                {
                  product.sale ? (<div className="absolute top-2 right-2 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded">Sale </div>) : (<div></div>)
                }
                {/* <div className="absolute top-2 right-2 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded">
                  {product.sale ? "Sale" : ""}
                </div> */}
              </div>
              <div className="p-4">
                {/* <div className="flex items-center mb-2">
              <span className="text-yellow-400 text-sm mr-2"></span>
              <span className="text-sm text-gray-500">({product.rating})</span>
            </div> */}
                <h5 className="text-lg font-bold text-gray-900">{product.name}</h5>
                <div className="flex items-center mt-2">
                  <span className=" font-bold text-lg" style={{ color: "#25BCCC" }}>&#8377; {product.price}</span>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ProductFeatures;
