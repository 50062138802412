const initialState = {
  orders: [],
  checkout: null,
  loading: false,
  error: null,
};

export const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_ORDER_SUCCESS':
      return { ...state, checkout: action.payload, loading: false };
    case 'CREATE_ORDER_FAILURE':
      return { ...state, error: action.payload, loading: false };
    case 'PAYMENT_VERIFICATION_SUCCESS':
      // Assuming response includes updated checkout with payment status
      return {
        ...state,
        checkout: { ...state.checkout, paymentStatus: 'Paid' },
        loading: false,
      };
    case 'PAYMENT_VERIFICATION_FAILURE':
      return { ...state, error: action.payload, loading: false };
    case 'FETCH_CHECKOUT_SUCCESS':
      return { ...state, checkout: action.payload, loading: false };
    case 'FETCH_CHECKOUT_FAILURE':
      return { ...state, error: action.payload, loading: false };

    case 'FETCH_CHECKOUT_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'FETCH_CHECKOUT_SUCCESS':
      return {
        ...state,
        loading: false,
        checkout: action.payload,
      };
    case 'FETCH_CHECKOUT_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case 'FETCH_ORDER_HISTORY_REQUEST':
      return { ...state, loading: true, error: null };
    case 'FETCH_ORDER_HISTORY_SUCCESS':
      return { ...state, loading: false, orders: action.payload };
    case 'FETCH_ORDER_HISTORY_FAILURE':
      return { ...state, loading: false, error: action.payload };


    default:
      return state;
  }
};
