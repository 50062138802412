// src/components/ForgotPasswordForm.js
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { forgotPassword } from './../../redux/actions/authActions';

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(forgotPassword({ email }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Forgot Password</h2>
      <input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
      <button type="submit">Send OTP</button>
    </form>
  );
};

export default ForgotPasswordForm;
