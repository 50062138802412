// src/components/SignupForm.js
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { signup } from './../../redux/actions/authActions';
import { Link, useNavigate } from 'react-router-dom';

const SignupForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await dispatch(signup({ name, email, phone, password }));
      if (response.status === 201) {
        navigate('/verify-otp', { state: { email } });
      }
    } catch (error) {
      alert("Signup failed: " + error.message);
    }
  };

  return (
    <div className="flex h-screen ">
      <div className="w-full max-w-xl m-auto  rounded p-5" style={{background: "#25BCCC"}}>   
          
        <form onSubmit={handleSubmit}>
          <div>
            <label className="block mb-2 text-[#25BCCC]">Full Name</label>
            <input
  className="w-full p-2 mb-6  border-b-2 border-[#25BCCC] outline-none focus:bg-gray-300"              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
             <label className="block mb-2 text-[#25BCCC]">E-Mail</label>
            <input
  className="w-full p-2 mb-6  border-b-2 border-[#25BCCC] outline-none focus:bg-gray-300"              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
             <label className="block mb-2 text-[#25BCCC]">Phone Number</label>
            <input
  className="w-full p-2 mb-6  border-b-2 border-[#25BCCC] outline-none focus:bg-gray-300"              type="text"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div>
             <label className="block mb-2 text-[#25BCCC]">Password</label>
            <input
  className="w-full p-2 mb-6  border-b-2 border-[#25BCCC] outline-none focus:bg-gray-300"              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div>   
            <button
              className="w-full bg-indigo-700 hover:bg-pink-700 text-white font-bold py-2 px-4 mb-6 rounded"
              type="submit"
            >
              Signup
            </button>       
          </div>       
        </form>  
        <footer>
          <Link to="/signin" className="text-white hover:text-pink-700 text-lg float-right">Already have an account? Sign in</Link>
        </footer>
      </div>
    </div>
  );
};

export default SignupForm;
