import Swal from 'sweetalert2';
import api from './../../service/api';



export const createOrder = (orderData, navigate) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post('/checkout/create-order', orderData, {
      headers: { Authorization: `Bearer ${token}` },
    });

    // Assuming response.data.checkout includes all necessary details
    dispatch({ type: 'CREATE_ORDER_SUCCESS', payload: response.data.checkout });
    navigate(`/confirmation/order/${response.data.checkout._id}`);
  } catch (error) {
    dispatch({ type: 'CREATE_ORDER_FAILURE', payload: error.message });
    Swal.fire('Order Creation Failed', error.message, 'error');
  }
};


export const verifyPayment = (paymentData, navigate) => async (dispatch) => {
  try {
    const response = await api.post('/checkout/verify-payment', paymentData);

    // Assuming response.data.checkout includes the updated checkout details
    dispatch({ type: 'PAYMENT_VERIFICATION_SUCCESS', payload: response.data.checkout });
    navigate(`/confirmation/order/${response.data.checkout._id}`);
    Swal.fire('Payment Successful', 'Thank you for your order!', 'success');
  } catch (error) {
    dispatch({ type: 'PAYMENT_VERIFICATION_FAILURE', payload: error.message });
    Swal.fire('Payment Verification Failed', error.message, 'error');
  }
};




export const fetchOrderHistory = () => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_ORDER_HISTORY_REQUEST' });

    const token = localStorage.getItem('token');
    const response = await api.get('/checkout/order-history', {
      headers: { Authorization: `Bearer ${token}` },
    });

    dispatch({ type: 'FETCH_ORDER_HISTORY_SUCCESS', payload: response.data.orders });
  } catch (error) {
    dispatch({
      type: 'FETCH_ORDER_HISTORY_FAILURE',
      payload: error.response?.data?.message || error.message,
    });
  }
};

