import React, { useState } from "react";
import api from "../../service/api";
import Swal from 'sweetalert2';

const ContactSection = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        try {
            const response = await api.post('/contact/create', formData);
            console.log(response.data);
            Swal.fire("Success", "Contact created successfully", "success");
          
            setFormData({
                name: '',
                email: '',
                phoneno: '',
                subject: '',
                message: ''
            });
        } catch (error) {
            console.error('Error sending message:', error);
            Swal.fire("Error",'Failed to send message. Please try again.');
        }
    }

  return (
    <section className="bg-blue-50 dark:bg-slate-800" id="contact">
      <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8 lg:py-20">
        <div className="mb-4">
          <div className="mb-6 max-w-3xl text-center md:mx-auto md:mb-12">
            <p
              className="text-base font-semibold uppercase tracking-wide"
              style={{ color: "#25BCCC" }}
            >
              Contact
            </p>
            <h2 className="font-heading mb-4 font-bold tracking-tight text-gray-900 dark:text-white text-3xl sm:text-5xl">
              Get in Touch
            </h2>
            <p className="mx-auto mt-4 max-w-3xl text-xl text-gray-600 dark:text-slate-400">
              In hac habitasse platea dictumst
            </p>
          </div>
        </div>
        <div className="flex items-stretch justify-center">
          <div className="grid md:grid-cols-2">
            <div className="h-full pr-6">
              <p className="mt-3 mb-12 text-lg text-gray-600 dark:text-slate-400">
                Class aptent taciti sociosqu ad litora torquent per conubia
                nostra, per inceptos himenaeos. Duis nec ipsum orci. Ut
                scelerisque sagittis ante, ac tincidunt sem venenatis ut.
              </p>
              <ul className="mb-6 md:mb-0">
                {[
                  {
                    iconPath: "M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0 M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z",
                    title: "Our Address",
                    details: ["Om Shree Vrindavan Apartment, Beside Ram Kutir Soc, Ramdev Park, Mira-Rd East, Mumbai-401107"],
                  },
                  {
                    iconPath: "M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2 M15 7a2 2 0 0 1 2 2 M15 3a6 6 0 0 1 6 6",
                    title: "Contact",
                    details: [" +91 7756048751", "+91 7718006913"],
                  },
                  {
                    iconPath: "M4 4h16a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2H4a2 2 0 0 1 -2 -2V6a2 2 0 0 1 2 -2zm0 2v.01L12 13L20 6v-.01M4 18l8 -5l8 5",
                    title: "Email",
                    details: [
                      "contact@pehell.com",
                      "support@pehell.com",
                    ],
                  },
                ].map((item, index) => (
                  <li className="flex mb-4" key={index}>
                    <div
                      className="flex h-10 w-10 items-center justify-center rounded"
                      style={{ backgroundColor: "#25BCCC", color: "#FFF" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="h-6 w-6"
                      >
                        <path d={item.iconPath}></path>
                      </svg>
                    </div>
                    <div className="ml-4">
                      <h3 className="mb-2 text-lg font-medium leading-6 text-gray-900 dark:text-white">
                        {item.title}
                      </h3>
                      {item.details.map((detail, idx) => (
                        <p className="text-gray-600 dark:text-slate-400" key={idx}>
                          {detail}
                        </p>
                      ))}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div
              className="card h-fit max-w-6xl p-5 md:p-12"
              id="form"
              style={{ border: `1px solid ${"#25BCCC"}` }}
            >
              
              <form id="contactForm" onSubmit={handleSubmit}>
                <div className="mb-6">
                  {["name", "email", "phone", "subject"].map((field, index) => (
                    <div className="mx-0 mb-1 sm:mb-4" key={index}>
                      <input
                        type={field === "email" ? "email" : "text"}
                        id={field}
                        autoComplete={field}
                        placeholder={`Your ${field}`}
                        className="w-full p-2 mb-6  border-b-2 border-[#25BCCC] outline-none focus:bg-gray-300"
                        name={field}
                        value={formData.field}
                        onChange={handleChange}
                      />
                    </div>
                  ))}
                  <div className="mx-0 mb-1 sm:mb-4">
                    <textarea
                      id="textarea"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      cols="30"
                      rows="3"
                      placeholder="Write your message..."
                      className="w-full p-2 mb-6  border-b-2  outline-none focus:bg-gray-300 border-[#25BCCC]"
                      ></textarea>
                  </div>
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="w-full"
                    style={{
                      backgroundColor: "#25BCCC",
                      color: "#FFF",
                      padding: "12px 24px",
                      borderRadius: "6px",
                    }}
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
