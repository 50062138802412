import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addToCart } from '../../redux/actions/cartActions';
import api from '../../service/api';
import Swal from 'sweetalert2';

const ProductDetail = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [mainImage, setMainImage] = useState('');
  const [quantity, setQuantity] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await api.get(`/products/viewproduct/${productId}`);
        setProduct(response.data);
        setMainImage(response.data.images[0]); // Set first image as default
      } catch (error) {
        console.error('Error fetching product details', error);
      }
    };
    fetchProductDetails();
  }, [productId]);

  const handleAddToCart = () => {
    if (product) {
      dispatch(addToCart(productId, quantity));
      Swal.fire("Success", "Product added to cart", "success");
    }
  };

  if (!product) return <p>Loading product...</p>;

  return (
    <div className="bg-gray-100">
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-wrap -mx-4">
          {/* Product Images */}
          <div className="w-full md:w-1/2 px-4 mb-8">
            <img src={mainImage} alt="Product" className="w-full h-auto rounded-lg shadow-md mb-4" id="mainImage" />
            <div className="flex gap-4 py-4 justify-center overflow-x-auto">
              {product && product.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Thumbnail ${index + 1}`}
                  className="size-16 sm:size-20 object-cover rounded-md cursor-pointer opacity-60 hover:opacity-100 transition duration-300"
                  onClick={() => setMainImage(image)}
                />
              ))}
            </div>
          </div>

          {/* Product Details */}
          <div className="w-full md:w-1/2 px-4">
            <h2 className="text-3xl font-bold mb-2">{product.name}</h2>
            {/* <p className="text-gray-600 mb-4">SKU: {product.sku || 'N/A'}</p> */}
            <div className="mb-4">
              <span className="text-2xl font-bold mr-2"> &#8377; {product.price}</span>
              {product.discountedPrice && (
                <span className="text-gray-500 line-through"> &#8377; {product.discountedPrice}</span>
              )}
            </div>
            <div className="flex items-center mb-4">
              {/* Star rating can be dynamically rendered here */}
              <span className="ml-2 text-gray-600">4.5 (120 reviews)</span>
            </div>
            <p className="text-gray-700 mb-6">{product.description}</p>

            {/* <div className="mb-6">
              <h3 className="text-lg font-semibold mb-2">Color:</h3>
              <div className="flex space-x-2">
                {product.colors.map((color, index) => (
                  <button
                    key={index}
                    className={`w-8 h-8 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 ${color}`}
                    style={{ backgroundColor: color }}
                  ></button>
                ))}
              </div>
            </div> */}

            <div className="mb-6">
              <label htmlFor="quantity" className="me-4 text-sm font-medium text-gray-700 mb-1">Quantity:</label>
              <input
                type="number"
                id="quantity"
                name="quantity"
                min="1"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                className="w-12 text-center rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>

            <div className="flex space-x-4 mb-6">

           

              <button
                onClick={()=>handleAddToCart(productId.product_id, quantity.quantity)}
                className=" flex gap-2 items-center text-white px-6 py-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                style={{background : "#25bccc"}}
              >
                Add to Cart
              </button>
              <button className="bg-gray-200 flex gap-2 items-center text-gray-800 px-6 py-2 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
                Wishlist
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
