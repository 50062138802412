import React from "react";
import BabyBanner from "./Hero";
import SpecialFeatures from "./Features";
import ProductFeatures from "../products/Product";
import ImageGallery from "../../components/filters/ImageGallery";
import ProductHots from "../products/HotsDeals";
import CTA from "./CTA";

const Landing = () =>{
    return(
        <>
        <BabyBanner/>
        <SpecialFeatures/>
        <ProductFeatures/>
        <ImageGallery/>
        <ProductHots/>
        <CTA/>
        </>
    )
}

export default Landing;