import React from "react";

const BabyBanner = () => {
  return (
    <div className="relative bg-gray-100 py-16 px-8 lg:px-20 flex items-center justify-center">
      {/* Background and overlay design */}
      <div className="absolute inset-0 bg-gray-50 -z-10" />
      <div className="absolute top-10 left-10 w-24 h-24 bg-yellow-200 rounded-full" />
      <div className="absolute bottom-10 right-10 w-32 h-32 bg-blue-100 rounded-full" />

      {/* Content section */}
      <div className="flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0 lg:space-x-16 max-w-6xl">
        {/* Text content */}
        <div className="text-center lg:text-left max-w-lg space-y-6">
          <h1 className="text-4xl font-bold text-gray-800">
            Anything For Your Baby
          </h1>
          <p className="text-gray-600 text-lg">
            It is a long established fact that a reader will be distracted by
            the readable content of a page.
          </p>
          <button className="text-white py-3 px-6 rounded-lg text-lg" style={{background: "#25BCCC"}}>
            Shop Now
          </button>
        </div>

        {/* Image content */}
        <div className="relative">
          {/* Baby image */}
          <div className="w-72 h-72 lg:w-96 lg:h-96 relative overflow-hidden rounded-lg shadow-lg">
            <img
              src="https://via.placeholder.com/300"
              alt="Baby"
              className="w-full h-full object-cover"
            />
          </div>

          {/* Decorations */}
          <div className="absolute top-8 left-8 w-12 h-12 bg-yellow-100 rounded-full flex items-center justify-center">
            🧸
          </div>
          <div className="absolute bottom-8 left-16 w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center">
            🍼
          </div>
          <div className="absolute bottom-12 right-8 w-8 h-8 bg-pink-100 rounded-full flex items-center justify-center">
            ⭐
          </div>
        </div>
      </div>
    </div>
  );
};

export default BabyBanner;
