import { combineReducers } from 'redux';
import authReducer from './authReducer';
import productReducer from './productReducer';
import cartReducer from './cartReducer';
import { orderReducer } from './checkoutReducer';


export default combineReducers({
  auth: authReducer,
  products : productReducer,
  cart: cartReducer,
  checkout : orderReducer,
});
