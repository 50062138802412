import React from "react";

const SpecialFeatures = () => {
  const features = [
    {
      id: 1,
      image: "https://img.freepik.com/free-photo/boy-playing-toys-with-woman_23-2147798347.jpg?ga=GA1.1.918187449.1718384370&semt=ais_hybrid", // Replace with actual image
      title: "Educational Toys",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      id: 2,
      image: "https://img.freepik.com/free-photo/full-shot-little-kid-sitting-tricycle_23-2149308376.jpg?ga=GA1.1.918187449.1718384370&semt=ais_hybrid", // Replace with actual image
      title: "Build & Create",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      id: 3,
      image: "https://img.freepik.com/free-photo/child-playing-home_1328-1111.jpg?ga=GA1.1.918187449.1718384370&semt=ais_hybrid", // Replace with actual image
      title: "Puzzles & Games",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
  ];

  return (
    <div className="bg-white py-16 px-8">
      {/* Heading */}
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold text-gray-800">Our Special Features</h2>
        <p className="text-gray-600 mt-4">
          Our purpose is to build solutions that remove barriers preventing
          people from doing their best work, and this is at the heart.
        </p>
      </div>

      {/* Features Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto">
        {features.map((feature) => (
          <div
            key={feature.id}
            className="border border-dashed border-gray-300 rounded-lg p-4 text-center"
          >
            {/* Feature Image */}
            <div className="relative mb-4">
              <img
                src={feature.image}
                alt={feature.title}
                className="rounded-md w-full object-cover h-48"
              />
            </div>
            {/* Feature Title */}
            <h3 className="text-xl font-semibold text-gray-800">
              {feature.title}
            </h3>
            {/* Feature Description */}
            <p className="text-gray-600 mt-2">{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SpecialFeatures;
