import logo from './logo.svg';
import './App.css';
import Landing from './pages/landing/Landing';
import { BrowserRouter } from 'react-router-dom';
import Navigation from './navigations/Routing';
import Navbar from './layouts/NavBar';
import Footer from './layouts/Footer'
import ScrollToTop from './ScrollToTop';
function App() {
  return (
    <div >
   <BrowserRouter>
   <Navbar/>
   <ScrollToTop/>
    <Navigation/>
    <Footer/>
   </BrowserRouter>
    </div>
  );
}

export default App;
