import React from 'react'

const CTA = () => {
    return (
        <>
            <section class="lg:px-28">
                <div class="relative flex flex-col md:flex-row">
                    <img class="w-full h-auto hidden md:inline md:h-52 2xl:h-64 md:w-1/4 cursor-pointer hover:contrast-150 transition-all duration-300" src="https://images.unsplash.com/photo-1671726805768-93b4c260766b?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" title="" alt="" />
                    <img class="w-full h-auto hidden md:inline md:h-52 2xl:h-64 md:w-1/4 cursor-pointer hover:contrast-150 transition-all duration-300" src="https://images.unsplash.com/photo-1664575599618-8f6bd76fc670?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" title="" alt="" />
                    <img class="w-full h-auto hidden md:inline md:h-52 2xl:h-64 md:w-1/4 cursor-pointer hover:contrast-150 transition-all duration-300" src="https://images.unsplash.com/photo-1640622843377-6b5af9417e70?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" title="" alt="" />
                    <img class="w-full h-auto md:h-52 2xl:h-64 md:w-1/4 cursor-pointer hover:contrast-150 transition-all duration-300" src="https://images.unsplash.com/photo-1671726805766-07bd41c8ebcf?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" title="" alt="" />
                </div>
                <div class="w-full  py-6 md:py-1" style={{background : "#25BCCC"}}>
                    <div class="w-max-3/4 mx-auto sm:4/5 md:w-3/4 lg:w-3/5 py-3 md:py-8 c-py-4 mx-auto flex flex-col md:flex-row items-center justify-items-center">
                        <div class="basis-2/12 xl:basis-1/12">
                            <img src="https://cdn.iconscout.com/icon/free/png-512/free-citroen-logo-icon-download-in-svg-png-gif-file-formats--company-brand-automotive-pack-logos-icons-2874693.png" title="" alt="" class="w-auto h-24" />
                        </div>
                        <div class="w-max-3/4 mx-auto sm:3/4 md:w-3/6 lg:w-3/4 c-w-3xl-66 text-center md:!text-start sm:pl-8 flex flex-col py-6">
                            <h1 class="font-semibold text-2xl text-white uppercase">Discover Joyful Spaces for Your Little Ones!</h1>
                            <p class="text-xl md:text-xl 2xl:text-1xl text-white mt-2">Trusted by Parents Everywhere for Safe, Fun, and Durable Toys & Furniture – Create Memories That Last a Lifetime. </p>
                        </div>
                        <div class="w-max-1/6 mx-auto md:w-auto text-center text-xl font-semibold text-white py-2 px-8 cursor-pointer md:!text-start border-2 border-white rounded-md hover:bg-white hover:!text-emerald-500 transition-all duration-300">
                            <button>Explore Now</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CTA;