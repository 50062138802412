import { ADD_TO_CART, VIEW_CART } from '../actions/cartActions';

const initialState = {
  cartItems: [],
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      return { ...state, cartItems: action.payload.items };
      
      case 'VIEW_CART':
        return {
          ...state,
          cartItems: action.payload.items || [], // Handle the case where the cart is empty
        };
    
        
    default:
      return state;
  }
};

export default cartReducer;
