import api from './../../service/api';

// Action Types
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_PRODUCT_DETAIL = 'FETCH_PRODUCT_DETAIL';
export const FILTER_PRODUCTS = 'FILTER_PRODUCTS';
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';

// Action Creators
export const fetchProducts = () => async (dispatch) => {
  try {
    const response = await api.get('/products/viewproduct');
    dispatch({ type: FETCH_PRODUCTS, payload: response.data });
  } catch (error) {
    console.error('Failed to fetch products', error);
  }
};

export const fetchProductDetail = (productId) => async (dispatch) => {
  try {
    const response = await api.get(`/products/viewproduct/${productId}`);
    dispatch({ type: FETCH_PRODUCT_DETAIL, payload: response.data });
  } catch (error) {
    console.error('Failed to fetch product detail', error);
  }
};

export const filterProducts = (filterParams) => async (dispatch) => {
  try {
    const response = await api.post('/products/filter', filterParams);
    dispatch({ type: FILTER_PRODUCTS, payload: response.data });
  } catch (error) {
    console.error('Failed to filter products', error);
  }
};

export const searchProducts = (name) => async (dispatch) => {
  try {
    const response = await api.get(`/products/search?name=${name}`);
    dispatch({ type: SEARCH_PRODUCTS, payload: response.data });
  } catch (error) {
    console.error('Failed to search products', error);
  }
};
