import React from 'react';



const About = () => {
  return (
    <div className="container mx-auto my-24">
      <h1 className="text-3xl font-semibold text-center mb-8">Welcome To Pehell</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        <div className="col-span-2">
          <p className="text-lg text-gray-700">
            Welcome to Pehell, where we believe in laying the foundation for your child&apos;s success from the very beginning.
          </p>
          <p className="mt-4 text-lg text-gray-700">
            Founded by Kunal Shah, a visionary with a background in mechanical engineering and product design, Pehell is an Indian company driven by a singular mission: to produce innovative kids&apos; development products that foster engagement and readiness in toddlers and young children.
          </p>
          <p className="mt-4 text-lg text-gray-700">
            In today&apos;s fast-paced world, parents often find themselves grappling with the pressures of ensuring their children are prepared for the competitive platforms they will encounter as they grow. However, at Pehell, we understand that true success begins with a strong foundation – the root of Parvarish – laid during a child&apos;s earliest years.
          </p>
          <p className="mt-4 text-lg text-gray-700">
            Our journey began with a simple yet profound realization: every child deserves the opportunity to explore, learn, and develop in an environment that nurtures their natural curiosity and creativity. With this vision in mind, we set out to create a diverse range of toys and furniture that not only entertain but also educate and engage young minds.
          </p>
          <p className="mt-4 text-lg text-gray-700">
            At Pehell, we&apos;re committed to crafting products that go beyond mere distraction. Each product in our collection is thoughtfully designed to stimulate cognitive development, encourage imaginative play, and promote physical activity. From interactive toys that spark curiosity to ergonomic furniture that supports proper posture and motor skills, every Pehell product is meticulously crafted to contribute to your child&apos;s overall well-being.
          </p>
          <p className="mt-4 text-lg text-gray-700">
            But our dedication to excellence doesn&apos;t stop there. We understand the importance of safety and quality in children&apos;s products, which is why we adhere to the highest standards of safety and durability in our manufacturing processes. You can trust that every Pehell product is not only fun and engaging but also safe for your little ones to enjoy.
          </p>
          <p className="mt-4 text-lg text-gray-700">
            As we continue on our mission to revolutionize early childhood development in India, we invite you to join us on this journey. Together, let&apos;s empower the next generation with the tools they need to thrive, grow, and succeed in an ever-changing world. Welcome to Pehell – where every product is a step towards a brighter future for your child.
          </p>
        </div>
        <div className="hidden sm:block col-span-1">
          <img
            src="https://cdn.arrivalsolutions.in/logo/Asset%2013pehell%20logo_.png"
            alt="Pehell Logo"
            width={300}
            height={300}
          />
        </div>
      </div>
    </div>
  );
};

export default About;
