import { SIGNUP, VERIFY_OTP, SIGNIN, FORGOT_PASSWORD, VERIFY_OTP_PASSWORD_RESET, RESET_PASSWORD, GET_PROFILE, LOGOUT } from './../actions/authActions';

const initialState = {
  user: null,
  token: null,
  profile: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP:
    case VERIFY_OTP:
    case FORGOT_PASSWORD:
    case VERIFY_OTP_PASSWORD_RESET:
    case RESET_PASSWORD:
      return { ...state, user: action.payload.user, token: action.payload.token };

    case SIGNIN:
      return { ...state, user: action.payload.user, token: action.payload.token };

    case GET_PROFILE:
      return { ...state, profile: action.payload };

    case LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default authReducer;
