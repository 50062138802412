import {
    FETCH_PRODUCTS,
    FETCH_PRODUCT_DETAIL,
    FILTER_PRODUCTS,
    SEARCH_PRODUCTS
  } from '../actions/productActions';
  
  const initialState = {
    products: [],
    productDetail: null,
    filteredProducts: [],
    searchedProducts: [],
  };
  
  const productReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_PRODUCTS:
        return { ...state, products: action.payload };
      case FETCH_PRODUCT_DETAIL:
        return { ...state, productDetail: action.payload };
      case FILTER_PRODUCTS:
        return { ...state, filteredProducts: action.payload };
      case SEARCH_PRODUCTS:
        return { ...state, searchedProducts: action.payload };
      default:
        return state;
    }
  };
  
  export default productReducer;
  