// components/ConfirmationPage.js

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';

const ConfirmationPage = () => {
  const location = useLocation();
  const { _id } = location.state || {};
  const {checkoutId} = useParams()
  console.log(checkoutId);

  return (
    <div className="flex items-center justify-center min-h-screen ">
      <div className="w-full max-w-2xl p-4 bg-white shadow-2xl dark:bg-gray-900 sm:p-10 sm:rounded-3xl">
        <div className="text-center">
          <div className="flex items-center justify-center w-20 h-20 mx-auto mb-6 bg-green-100 rounded-full dark:bg-green-700">
            <svg className="h-12 w-12 text-green-600 dark:text-green-100" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              strokeWidth="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
              <path strokeLinecap="round" strokeLinejoin="round"
                d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
            </svg>
          </div>
          <h1 className="text-4xl font-extrabold text-green-700 dark:text-green-400"> Thank you for your order.</h1>
          <p className="mt-4 text-lg text-gray-800 dark:text-gray-300">
            Your order has been placed successfully.
          </p>
          <p className="mt-6 text-xl text-[#25BCCC] ">
            Your order ID is: <span className="font-bold"> {checkoutId} </span>
          </p>
          <p className="mt-4 text-sm text-[#111] ">
            If you have any questions or need further assistance, feel free to contact us at:
            <a href="mailto:suport@pehell.com" className="font-medium text-[#25BCCC]   underline">
              support@pehell.com
            </a>
          </p>
        </div>
        <div className="mt-8 text-center">
          <Link to="/"
            className="inline-block px-6 py-2 text-lg font-medium text-white transition-transform rounded-full shadow-lg bg-[#25BCCC]">
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPage;